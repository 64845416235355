import React, { useState, useEffect, useCallback, useRef } from "react";

const readyPromises = [];

function spotifyPlayerReady() {
    return new Promise(resolve => {
        if ('Spotify' in window) {
            resolve();
        } else {
            readyPromises.push(resolve);
            window.onSpotifyWebPlaybackSDKReady = () => {
                readyPromises.forEach(resolvePromise => resolvePromise());
            };
        }
    });
}

export function SpotifyPlayer({
  name,
  initialVolume = 1.0,
  refreshRateMs = 200,
  autoConnect = true,
  onRequestAccessToken,
  onError = () => {},
  onStateChange = () => {},
  onWaitingForDevice = () => {},
  children,
}) {
  const [isReady, setIsReady] = useState(false);
  const playbackInstance = useRef(null);
  const statePollingInterval = useRef();

  const handleState = useCallback(async state => {
    if (state) {
      onStateChange(state);
    } else {
      const {
        _options: { id: device_id },
      } = playbackInstance.current;

      clearInterval(statePollingInterval.current);
      onWaitingForDevice({ device_id });
    }
  }, [onStateChange, statePollingInterval, onWaitingForDevice]);

  const setupWebPlaybackInstance = useCallback(() => {
    const { Player } = window.Spotify;
    
    const webPlaybackInstance = new Player({
      name,
      volume: initialVolume,
      getOAuthToken: callback => {
        if (typeof onRequestAccessToken !== "undefined") {
          const userAccessToken = onRequestAccessToken();
          callback(userAccessToken);
        }
      }
    });
    
    webPlaybackInstance.on("initialization_error", e => {
      onError(e);
    });
    
    webPlaybackInstance.on("authentication_error", e => {
      onError(e);
    });
  
    webPlaybackInstance.on("account_error", e => {
      onError(e);
    });
  
    webPlaybackInstance.on("playback_error", e => {
      onError(e);
    });
  
    webPlaybackInstance.on("player_state_changed", async state => {
      await handleState(state);
    });
  
    webPlaybackInstance.on("ready", data => {
      onWaitingForDevice(data);
    });
  
    if (autoConnect) {
      webPlaybackInstance.connect();
    }

    playbackInstance.current = webPlaybackInstance;
  }, [autoConnect, initialVolume, name, onError, onRequestAccessToken, handleState, onWaitingForDevice]);

  function setupWaitingForDevice() {
    return new Promise(resolve => {
      playbackInstance.current.on('ready', data => {
        resolve(data);
      });
    });
  }

  useEffect(() => {
    if (!isReady) {
      spotifyPlayerReady().then(() => {
        setupWebPlaybackInstance();
      }).then(() => {
        // Wait for device to be ready
        return setupWaitingForDevice();
      }).then(deviceData => {
        onWaitingForDevice(deviceData);
      }).then(() => {
        setIsReady(true);
      });
    }
  }, [isReady, setupWebPlaybackInstance, onWaitingForDevice]);

  if (!isReady) {
    return <></>;
  }

  return (
    <>
      {children}
    </>
  );
}
