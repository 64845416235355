import React from 'react';
import './App.css';

import Login from './components/Client/login';
import Player from './components/Player';
import Search from './components/Search';
import Game from './components/Game';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Login>
          <Player />
          <Search />
          <Game />
        </Login>
      </header>
    </div>
  );
}

export default App;
