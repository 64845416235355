import React, { useEffect, useContext, Children } from 'react';
import styled from 'styled-components';
import { Client } from 'spotify-sdk';

import PlayerContext from '../../context/PlayerContext';

import config from './config';
import { Button } from '../Search/FormStyles';

export const client = Client.instance;

client.settings = {
    clientId: config.clientId,
    secretId: config.secretId,
    scopes: [
        'user-read-email',
        'user-read-private',
        'user-read-playback-state',
        'user-modify-playback-state',
        'user-read-currently-playing',
        'playlist-read-collaborative',
        'playlist-modify-public',
        'playlist-read-private',
        'playlist-modify-private',
        'streaming',
    ],
    redirect_uri: config.redirectUri,
};

const LoginButton = styled(Button)``;

export default function Login({ children }) {
    const { loggedIn, setLoggedIn, saveContext } = useContext(PlayerContext);

    useEffect(() => {
        let timeout;
        const sessionToken = sessionStorage.getItem('token');
        const sessionTimeout = sessionStorage.getItem('tokenExpire');
        if (sessionToken && sessionTimeout && parseInt(sessionTimeout, 10) > Date.now()) {
            client.token = sessionStorage.token;
            const time = parseInt(sessionTimeout, 10) - Date.now();
            setLoggedIn(true);
            timeout = setTimeout(() => {
                setLoggedIn(false);
            }, time);
        } else {
            const urlParams = new URLSearchParams(window.location.hash.substr(1));
            if (urlParams.has('access_token') && urlParams.has('expires_in') && sessionToken !== urlParams.get('access_token')) {
                const token = urlParams.get('access_token');
                const expire = Date.now() + parseInt(urlParams.get('expires_in'), 10) * 1000;
                sessionStorage.setItem('token', token);
                sessionStorage.setItem('tokenExpire', expire);
                client.token = token;
                const time = expire - Date.now();
                setLoggedIn(true);
                timeout = setTimeout(() => {
                    setLoggedIn(false);
                }, time);
            }
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [loggedIn, setLoggedIn]);

    console.log({ loggedIn });

    if (loggedIn) {
        return (
            <>{Children.toArray(children)}</>
        );
    }

    return (
        <LoginButton 
            type="button" 
            onClick={() => {
                client.login().then(url => {
                    saveContext();
                    window.location.href = url;
                });
            }}
            active
        >
            Login
        </LoginButton>
    );
}
