import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { UserHandler, PlaylistHandler } from 'spotify-sdk';

import PlayerContext from '../../context/PlayerContext';
import { Button } from '../Search/FormStyles';
import shuffleArray from '../../utils/Array.shuffle';
import Song, { SongList } from '../Song';
import { SpotifyPlayer } from '../SpotifyPlayer';

const Wrapper = styled.div`
    margin-top: 2rem;
`;

const Players = styled.div`
    margin-bottom: 2rem;
`;
const Info = styled.div``;

const user = new UserHandler();
const playlistHandler = new PlaylistHandler();

const play = ({
    spotifyUri,
    deviceId,
}) => fetch(
    `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
    {
        method: 'PUT',
        ...(spotifyUri && { body: JSON.stringify({ uris: [spotifyUri] }) }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.token}`
        },
    }
);

const pause = ({ deviceId }) => fetch(
    `https://api.spotify.com/v1/me/player/pause?device_id=${deviceId}`,
    {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.token}`
        },
    }
);

export default function Game() {
    const { isLocked, players, setPlayers, lockGame, resetContext } = useContext(PlayerContext);
    const [showResult, setShowResult] = useState(false);
    const [songIndex, setSongIndex] = useState(0);
    const [deviceId, setDeviceId] = useState();
    const [isPlaying, setPlaying] = useState(false);

    if (players.length === 0) {
        return <></>;
    }

    if (isLocked) {
        return (
            <Wrapper>
                <Players>{players.length} Spieler / Songtitel eingetragen.</Players>
                {songIndex < players.length && (
                    <>
                        <SpotifyPlayer
                            name="Gamequiz for Spotify"
                            initialVolume={1.0}
                            autoConnect={true}
                            onRequestAccessToken={() => sessionStorage.token}
                            onError={e => {
                                console.warn(e);
                            }}
                            onStateChange={state => {
                                console.log('state change', state);
                            }}
                            onWaitingForDevice={deviceData => {
                                setDeviceId(deviceData.device_id);
                            }}
                            onDeviceSelected={() => {
                                console.log('Device selected');
                            }}
                        />
                        {deviceId && (
                            <>
                                {songIndex > 0 && (
                                    <Button
                                        onClick={() => {
                                            if (isPlaying) {
                                                pause({ deviceId }).then(() => {
                                                    setPlaying(false);
                                                });
                                            } else {
                                                play({ deviceId }).then(() => {
                                                    setPlaying(true);
                                                });
                                            }
                                        }}
                                    >
                                        {isPlaying ? 'Pause' : 'Fortsetzen'}
                                    </Button>
                                )}
                                <Button
                                    onClick={() => {
                                        const currentSong = players[songIndex].song;

                                        play({
                                            spotifyUri: currentSong.uri,
                                            deviceId,
                                        }).then(() => {
                                            setPlaying(true);
                                            setSongIndex(songIndex + 1);
                                        });
                                    }}
                                    active
                                >
                                    {songIndex === 0 ? 'Abspielen' : 'Nächster Song'}
                                </Button>
                            </>
                        )}
                    </>
                )}
                {songIndex === players.length && !showResult && (
                    <>
                        <Button
                            onClick={() => {
                                if (isPlaying) {
                                    pause({ deviceId }).then(() => {
                                        setPlaying(false);
                                    });
                                } else {
                                    play({ deviceId }).then(() => {
                                        setPlaying(true);
                                    });
                                }
                            }}
                        >
                            {isPlaying ? 'Pause' : 'Fortsetzen'}
                        </Button>
                        <Button
                            onClick={() => {
                                if (window.confirm('Ergebnis wirklich anzeigen?')) {
                                    pause({ deviceId });
                                    setShowResult(true);
                                }
                            }}
                            active
                        >
                            Ergebnis anzeigen
                        </Button>
                    </>
                )}
                {songIndex === players.length && showResult && (
                    <>
                        <SongList>
                            {players.map(({ player, song }) => (
                                <Song key={`${player}-${song.id}`} player={player} song={song} />
                            ))}
                        </SongList>
                        <Button
                            onClick={() => {
                                user.me().then(me => {
                                    const playlistName = window.prompt('Bitte Name für die neue Playlist eingeben:', 'Spotify-Game');
                                    if (!playlistName) {
                                        return;
                                    }
                                    playlistHandler.create(me.id, playlistName, false).then(newPlaylist => {
                                        const songs = players.map(curPlayer => curPlayer.song);
                                        newPlaylist.addTrack(songs);
                                    });
                                });
                            }}
                        >
                            Playlist speichern
                        </Button>
                        <Button
                            onClick={() => {
                                setDeviceId(undefined);
                                setShowResult(false);
                                setSongIndex(0);
                                resetContext();
                            }}
                            active
                        >
                            Neues Spiel starten
                        </Button>
                    </>
                )}
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Players>{players.length} Spieler / Songtitel eingetragen.</Players>
            {players.length < 3
                ? <Info>Es sind mindestens 3 Spieler erforderlich.</Info>
                : (
                    <Button
                        onClick={() => {
                            const shuffledPlayers = shuffleArray(players);
                            setPlayers(shuffledPlayers);
                            lockGame(true);
                        }}
                        active
                    >
                        Raterunde starten
                    </Button>
                )}
        </Wrapper>
    );
}