import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const PlayerContext = createContext(null);
export default PlayerContext;

const defaultValues = JSON.parse(sessionStorage.getItem('savedContext')) || { player: '', players: [] };

export function PlayerProvider({ children }) {
    const [loggedIn, setLoggedIn] = useState(false);
    const [isLocked, lockGame] = useState(false);
    const [player, setPlayer] = useState(defaultValues.player);
    const [players, setPlayers] = useState(defaultValues.players);

    const addPlayer = player => {
        setPlayers([...players, player]);
    }

    const saveContext = () => {
        sessionStorage.setItem('savedContext', JSON.stringify({
            player,
            players,
        }));
    }

    return (
        <PlayerContext.Provider
            value={{
                loggedIn,
                setLoggedIn,
                isLocked,
                lockGame,
                player,
                setPlayer,
                players,
                setPlayers,
                addPlayer,
                saveContext,
                resetContext: () => {
                    lockGame(false);
                    setPlayer('');
                    setPlayers([]);
                }
            }}
        >
            {children}
        </PlayerContext.Provider>
    );
}

PlayerProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
