import React, { useContext, useRef } from 'react';
import styled from 'styled-components';

import PlayerContext from '../../context/PlayerContext';

import { Form, Label, Input, Button } from '../Search/FormStyles';

const elementHeight = 40;

const Wrapper = styled.div`
    margin: 0 auto;
    width: 80%;
    max-width: 600px;
`;

const EditButton = styled.button`
    background-color: transparent;
    border: 0;
    border-radius: ${elementHeight / 2}px;
    color: #fff;
    font-size: ${elementHeight / 2}px;
    line-height: ${elementHeight}px;
    margin: 0 0.5rem;
    padding: 0 ${elementHeight / 2}px;
    height: ${elementHeight}px;

    transition: all 0.3s ease;

    &:hover {
        background-color: #1DB954;
        cursor: pointer;
    }
`;

export default function Player() {
    const { isLocked, player, setPlayer } = useContext(PlayerContext);
    const input = useRef();

    if (isLocked) {
        return <></>;
    }

    return (
        <Wrapper>
            {player === '' && <h3>Spielernamen eingeben:</h3>}
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    if (player === '') {
                        setPlayer(input.current.value.trim());
                    } else {
                        setPlayer('');
                    }
                }}
            >
                <Label>Spieler: </Label>
                {player === ''
                    ? (
                        <>
                            <Input type="text" ref={input} autoFocus />
                            <Button type="submit" active>
                                {player === '' ? 'Speichern' : 'Ändern'}
                            </Button>
                        </>
                    )
                    : (
                        <>
                            <span>{player}</span>
                            <EditButton type="submit">&#9998;</EditButton>
                        </>
                    )}
            </Form>
        </Wrapper>
    );
}
