import React from 'react';
import { SongItem, SongImg, SongContent, SongName, SongArtists } from './Styles';

export { SongList } from './Styles';

export default function Song({ as, player, song, hover, onClick }) {
    return (
        <SongItem as={as} hover={hover} onClick={onClick}>
            <SongImg
                src={song.album.images[2].url}
                alt={`${song.name} - Cover`}
                width={song.album.images[2].width}
                height={song.album.images[2].height}
            />
            <SongContent>
                {player && <SongName>{player}:</SongName>}
                <SongName>{song.name}</SongName>
                <SongArtists>
                    {song.artists.map((artist, i) => {
                        if (i === song.artists.length - 1) {
                            return <span key={`${artist.name}-no-comma`}>{artist.name}</span>;
                        }
                        return (
                            <React.Fragment key={artist.name}>
                                <span>{artist.name}</span>{', '}
                            </React.Fragment>
                        );
                    })}
                </SongArtists>
            </SongContent>
        </SongItem>
    );
}