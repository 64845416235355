import styled from 'styled-components';

const elementHeight = 40;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0 2rem;

    @media screen and (min-width: 600px) {
        flex-direction: row;
    }
`;

export const Label = styled.label`
    display: inline-block;
    margin: 0.4rem 0;

@media screen and (min-width: 600px) {
    margin: 0 10px 0 0;
}
`;

export const Input = styled.input`
    background-color: #fff;
    border: 2px solid #000;
    border-radius: ${elementHeight / 2}px;
    font-size: ${elementHeight / 2}px;
    line-height: ${elementHeight}px;
    margin: 0.4rem 0;
    padding: 0 ${elementHeight / 2}px;
    width: 400px;
    height: ${elementHeight}px;
    max-width: 100%;

    &:focus {
        border-color: #1DB954;
        outline: none;
    }

    @media screen and (min-width: 600px) {
        margin: 0 0.5rem;
    }

`;

export const Button = styled.button`
    background-color: ${({ active }) => active ? '#1DB954' : '#fff'};
    border: 2px solid #1DB954;
    border-radius: ${elementHeight / 2}px;
    color: ${({ active }) => active ? '#fff' : '#000'};
    font-size: ${elementHeight / 2}px;
    line-height: ${elementHeight}px;
    margin: 0.4rem 0;
    padding: 0 ${elementHeight / 2}px;
    height: ${elementHeight}px;

    transition: all 0.3s ease;

    &:hover {
        background-color: ${({ active }) => active ? '#189443' : '#1DB954'};
        color: #fff;
        cursor: pointer;
    }

    @media screen and (min-width: 600px) {
        margin: 0 0.5rem;
    }
`;
