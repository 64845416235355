import styled, { css } from 'styled-components';

export const SongList = styled.ul`
    list-style-type: none;
    margin: 2rem 0;
    padding: 0;
`;

export const SongItem = styled.li`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: 1rem 0.5rem;

    ${props => props.hover && css`
        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
            cursor: pointer;
        }
    `}
`;

export const SongImg = styled.img`
    margin-right: 1rem;
`;

export const SongContent = styled.div`
    text-align: left;
`;

export const SongName = styled.strong`
    display: block;
`;

export const SongArtists = styled.span`
    display: block;
`;
