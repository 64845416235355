import React, { useContext, useState, useRef } from 'react';
import styled from 'styled-components';
import { TrackHandler } from 'spotify-sdk';

import { Form, Label, Input, Button } from './FormStyles';

import PlayerContext from '../../context/PlayerContext';
import Song, { SongList } from '../Song';

const Wrapper = styled.div`
    margin: 0 auto;
    width: 80%;
    max-width: 600px;
`;

const H3 = styled.h3`
    margin: 0 0 0.5rem;
`;

const track = new TrackHandler();

export default function Search() {
    const { setLoggedIn, isLocked, player, setPlayer, addPlayer } = useContext(PlayerContext);
    const [song, setSong] = useState(null);
    const [list, setList] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const input = useRef();

    if (player === '' || isLocked) {
        return <></>;
    }

    return (
        <Wrapper>
            {(list.length > 0 || !song) && (
                <>
                    <H3>Songtitel suchen:</H3>
                    <Form
                        onSubmit={e => {
                            e.preventDefault();
                            if (!isSearching && input.current.value.trim()) {
                                setIsSearching(true);

                                track.search(input.current.value, { limit: 5 }).then(trackList => {
                                    console.log(trackList);
                                    setList(trackList);
                                }).catch(() => {
                                    setLoggedIn(false);
                                }).finally(() => {
                                    setIsSearching(false);
                                });
                            }
                        }}
                    >
                        <Label>Song: </Label>
                        <Input type="search" autoComplete="off" ref={input} disabled={isSearching} autoFocus />
                        <Button type="submit" active>Suchen</Button>
                    </Form>
                </>
            )}
            {list.length === 0 && song && (
                <>
                    <h3>Ausgewählter Song</h3>
                    <Song as="div" song={song} />
                    <Button
                        type="button"
                        onClick={() => {
                            setSong(null);
                        }}
                    >
                        Song ändern
                    </Button>
                    <Button
                        type="button"
                        onClick={() => {
                            addPlayer({ player, song });
                            setPlayer('');
                            setSong(null);
                            if (input.current) {
                                input.current.value = '';
                            }
                        }}
                        autoFocus
                        active
                    >
                        Bestätigen / Nächster Spieler
                    </Button>
                </>
            )}
            {list.length > 0 && (
                <SongList>
                    {list.map(item => (
                        <Song
                            key={item._id}
                            song={item}
                            onClick={() => {
                                setList([]);
                                setSong(item);
                                input.current.value = '';
                            }}
                            hover
                        />
                    ))}
                </SongList>
            )}
        </Wrapper>
    );
}